export const ADD_VENUES = 'VenuesPage/ADD_VENUES'
export const ADD_VISIBLE_VENUES = 'VenuesPage/ADD_VISIBLE_VENUES'
export const CLEAR_FILTERS = 'VenuesPage/CLEAR_FILTERS'
export const CLEAR_STATE = 'VenuesPage/CLEAR_STATE'
export const GET_USER_LOCATION = 'VenuesPage/GET_USER_LOCATION'
export const GET_VENUES = 'VenuesPage/GET_VENUES'
export const SET_CENTER_LOCATION = 'VenuesPage/SET_CENTER_LOCATION'
export const SET_FILTERS = 'VenuesPage/SET_FILTERS'
export const SET_INCOMING_VENUES = 'VenuesPage/SET_INCOMING_VENUES'
export const SET_LIST_VISIBILITY = 'VenuesPage/SET_LIST_VISIBILITY'
export const SET_LOADING_MAP = 'VenuesPage/SET_LOADING_MAP'
export const SET_LOADING_VENUES = 'VenuesPage/SET_LOADING_VENUES'
export const SET_MAP_VISIBILITY = 'VenuesPage/SET_MAP_VISIBILITY'
export const SET_NEXT_PAGE = 'VenuesPage/SET_NEXT_PAGE'
export const SET_IS_NEARBY_SEARCH = 'VenuesPage/SET_IS_NEARBY_SEARCH'
export const SET_POPUP_VISIBILITY = 'VenuesPage/SET_POPUP_VISIBILITY'
export const SET_SHOW_SEARCH_HERE = 'VenuesPage/SET_SHOW_SEARCH_HERE'
export const SET_SHOW_USER_MARKER = 'VenuesPage/SET_SHOW_USER_MARKER'
export const SET_USER_LOCATION = 'VenuesPage/SET_USER_LOCATION'
export const SET_VENUES = 'VenuesPage/SET_VENUES'
export const SET_VISIBLE_VENUES = 'VenuesPage/SET_VISIBLE_VENUES'
export const SET_WELCOME_VISIBILITY = 'VenuesPage/SET_WELCOME_VISIBILITY'
export const SET_USES_VISIBILITY = 'VenuesPage/SET_USES_VISIBILITY'
