export const CLEAR_ERRORS = 'CreateMapathon/CLEAR_ERRORS'
export const CLEAR_STATE = 'CreateMapathon/CLEAR_STATE'
export const CREATE_MAPATHON = 'CreateMapathon/CREATE_MAPATHON'
export const CREATE_POSTER = 'CreateMapathon/CREATE_POSTER'
export const DELETE_POSTER = 'CreateMapathon/DELETE_POSTER'
export const GET_TEAMS = 'CreateMapathon/GET_TEAMS'
export const GET_USER_LOCATION = 'CreateMapathon/GET_USER_LOCATION'
export const SET_ERRORS = 'CreateMapathon/SET_ERRORS'
export const SET_LOADING_TEAMS = 'CreateMapathon/SET_LOADING_TEAMS'
export const SET_LOCATION_COORDINATES =
  'CreateMapathon/SET_LOCATION_COORDINATES'
export const SET_POSTER = 'CreateMapathon/SET_POSTER'
export const SET_TEAMS = 'CreateMapathon/SET_TEAMS'
