export const CLEAR_ERRORS = 'CreateReview/CLEAR_ERRORS'
export const CLEAR_STATE = 'CreateReview/CLEAR_STATE'
export const GET_VENUE = 'CreateReview/GET_VENUE'
export const CREATE_PHOTO = 'CreateReview/CREATE_PHOTO'
export const CREATE_REVIEW = 'CreateReview/CREATE_REVIEW'
export const DELETE_PHOTO = 'CreateReview/DELETE_PHOTO'
export const SET_ERRORS = 'CreateReview/SET_ERRORS'
export const SET_LOADING_VENUE = 'CreateReview/SET_LOADING_VENUE'
export const SET_PHOTO = 'CreateReview/SET_PHOTO'
export const SET_VENUE = 'CreateReview/SET_VENUE'
export const SET_HOW_TO_RATE_VISIBILITY =
  'VenuesPage/SET_HOW_TO_RATE_VISIBILITY'
