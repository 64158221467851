export const CLEAR_ERRORS = 'Team/CLEAR_ERRORS'
export const CLEAR_INVITATIONS_STATE = 'Team/CLEAR_INVITATIONS_STATE'
export const CLEAR_STATE = 'Team/CLEAR_STATE'
export const CREATE_AVATAR = 'Team/CREATE_AVATAR'
export const CREATE_PETITION = 'Team/CREATE_PETITION'
export const DELETE_AVATAR = 'Team/DELETE_AVATAR'
export const EDIT_TEAM = 'Team/EDIT_TEAM'
export const GET_TEAM = 'Team/GET_TEAM'
export const GET_USERS = 'Team/GET_USERS'
export const JOIN_TEAM = 'Team/JOIN_TEAM'
export const PROMOTE_MEMBER = 'Team/PROMOTE_MEMBER'
export const REMOVE_MANAGER = 'Team/REMOVE_MANAGER'
export const REMOVE_MEMBER = 'Team/REMOVE_MEMBER'
export const SET_AVATAR = 'Team/SET_AVATAR'
export const SET_EDIT_IS_VISIBLE = 'Team/SET_EDIT_IS_VISIBLE'
export const SET_ERRORS = 'Team/SET_ERRORS'
export const SET_LOADING_TEAM = 'Team/SET_LOADING_TEAM'
export const SET_LOADING_USERS = 'Team/SET_LOADING_USERS'
export const SET_TEAM = 'Team/SET_TEAM'
export const SET_USERS = 'Team/SET_USERS'
