export const ADD_MAPATHONS = 'MapathonsPage/ADD_MAPATHONS'
export const CLEAR_STATE = 'MapathonsPage/CLEAR_STATE'
export const GET_MAPATHONS = 'MapathonsPage/GET_MAPATHONS'
export const SET_LOADING_MAPATHONS = 'MapathonsPage/SET_LOADING_MAPATHONS'
export const SET_MAPATHONS = 'MapathonsPage/SET_MAPATHONS'
export const SET_NEXT_PAGE = 'MapathonsPage/SET_NEXT_PAGE'
export const CLEAR_FILTERS = 'MapathonsPage/CLEAR_FILTERS'
export const SET_FILTERS = 'MapathonsPage/SET_FILTERS'
export const SET_LIST_VISIBILITY = 'MapathonsPage/SET_LIST_VISIBILITY'
export const SET_POPUP_VISIBILITY = 'MapathonsPage/SET_POPUP_VISIBILITY'
