export const CLEAR_ERRORS = 'User/CLEAR_ERRORS'
export const CLEAR_STATE = 'User/CLEAR_STATE'
export const CREATE_AVATAR = 'User/CREATE_AVATAR'
export const DELETE_AVATAR = 'User/DELETE_AVATAR'
export const EDIT_USER = 'User/EDIT_USER'
export const GET_USER = 'User/GET_USER'
export const LEAVE_MAPATHON = 'User/LEAVE_MAPATHON'
export const LEAVE_TEAM = 'User/LEAVE_TEAM'
export const SET_AVATAR = 'User/SET_AVATAR'
export const SET_EDIT_IS_VISIBLE = 'User/SET_EDIT_IS_VISIBLE'
export const SET_ERRORS = 'User/SET_ERRORS'
export const SET_LOADING_USER = 'User/SET_LOADING_USER'
export const SET_USER = 'User/SET_USER'
export const SIGN_OUT = 'User/SIGN_OUT'

export const CLEAR_PETITIONS_STATE = 'User/CLEAR_PETITIONS_STATE'
export const ADD_PETITIONS = 'User/ADD_PETITIONS'
export const CHANGE_FILTER = 'User/CHANGE_FILTER'
export const CHANGE_PETITION_STATE = 'User/CHANGE_PETITION_STATE'
export const EDIT_PETITION = 'User/EDIT_PETITION'
export const GET_PETITIONS = 'User/GET_PETITIONS'
export const REMOVE_PETITION = 'User/REMOVE_PETITION'
export const SET_LOADING_PETITIONS = 'User/SET_LOADING_PETITIONS'
export const SET_NEXT_PAGE = 'User/SET_NEXT_PAGE'
export const SET_PETITIONS = 'User/SET_PETITIONS'
